.cancelAccount {
  @media screen and (max-width: 1180px) {
    padding: 0 20px;
  }
}

.contain {
  border-top: 2px solid #e7e7e7;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1140px;
  padding: 70px 0 50px 0;
  width: 100%;

  @media screen and (max-width: 767px) {
    padding: 50px 0;
  }
}

.header {
  margin-bottom: 1em;
}

.form {
  display: flex;
  align-content: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    align-items: unset;
    flex-direction: column;
  }
}

.cancelChkbx {
  margin-bottom: 1em;

  label {
    font-size: 1rem;
  }
}

.cancelBtn {
  display: flex;
  margin-left: auto;
  width: 300px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  &:disabled {
    background: var(--grey);
  }
}
