.pastLicensingContent {
  display: flex;
  flex-direction: column;
  background: white;
  max-height: 712px;
  max-width: 420px;
  border: 1px solid black;
  padding: 0 5rem 2rem 5rem;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-y: scroll;
  overflow-x: hidden;

  .exitButton svg {
    width: 20px;
    height: 20px;
    margin-left: 28.7rem;
    margin-top: 1rem;
    cursor: pointer;

    g {
      fill: black;
    }
  }

  .portalLink {
    font-size: 2em;
    margin-left: 3.1rem;
    margin-bottom: 1rem;

    a {
      text-decoration: none;
      color: #745bb4;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .licenseHeaders,
  .licenseInformation {
    display: flex;
    justify-content: space-between;

    p {
      font-size: 18px;
      font-family: NeuzeitGro, georgia;
      padding: 10px 0;
    }
  }

  .licenseHeaders {
    text-decoration: underline;
  }

  .licenseInformation:nth-child(even) {
    background: #dac8ef;
  }

  .licenseInformation:nth-child(odd) {
    background: #fff;
  }

  ul {
    line-height: 1.5rem;
    font-size: 15px;
    font-family: NeuzeitGro, georgia;
  }
}
