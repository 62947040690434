@import "mixins";

.container,
.containerTeamPage {
  display: flex;
  background: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  height: 101%;
  width: 101%;
}

.containerTeamPage {
  flex-direction: column;
  background-color: rgba(244, 244, 244, 0.5);
  overflow: hidden;
}

.downloadMessageContainer {
  color: #3a3532;
  padding: 10px 50px 50px 50px;
}

.messageText {
  display: block;
  text-align: center;

  a {
    color: #745bb4;
    text-decoration: none;
  }
}

.hTwo {
  font-family: "elena-web-basic", georgia;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.0032em;
  font-size: 4.2em;
  text-align: center;
  padding-bottom: 30px;
}

.wrap {
  @include relative;
  background: #fff;
  border: 2px solid $black;
  display: inline-block;
  margin: -325px 0 0;
  max-height: 650px;
  max-width: 745px;
  overflow: hidden;
  overflow-y: scroll;

  top: 50%;

  @include mobile {
    border: none;
    height: 100%;
    margin-top: 0;
    min-height: 100%;
    top: 0;
    width: 100%;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .close {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 2;
    padding: 20px 20px 0 0;

    span {
      svg {
        height: 20px;
        width: 20px;

        g {
          fill: $black;
        }
      }
    }

    &.white {
      span {
        @include center-sprite(mood_remove);
        left: auto;
        right: 15px;
        margin: 0;
        top: 15px;
      }
    }

    @include mobile {
      margin-top: 0;
      top: 0;
      right: 10px;
      width: 100% !important;
    }
  }

  .target {
    height: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 16px;
    min-width: 500px;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: rgba(0, 0, 0, 0.2);
    }

    @include mobile {
      overflow: visible;
      height: auto;
      padding: 0;
      min-width: 0;
    }
  }
}
