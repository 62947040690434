.homeHero {
  height: 800px;
  position: relative;

  @media screen and (max-width: 767px) {
    height: 475px;
  }

  .prismicButton {
    margin: 0;

    @media screen and (max-width: 767px) {
      margin: 0 0 1rem 10px;
      width: 190px;
    }

    .link {
      margin-left: 0;

      @media screen and (max-width: 767px) {
        padding-bottom: 0;
      }
    }
  }

  .hOne {
    font-family: ObibokInversed;
    font-weight: 400;
  }

  .hTwo {
    font-family: ObibokInversed;
    font-weight: 400;
  }

  .img {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .contain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: 0;
    position: relative;
    height: 100%;
    width: 55%;

    @media screen and (max-width: 1024px) {
      margin: 0 2.5rem;
      width: unset;
    }

    @media screen and (max-width: 767px) {
      justify-content: flex-end;
      margin: 0 1.5rem;
    }
  }

  .content {
    margin-right: 5rem;

    @media screen and (max-width: 1024px) {
      margin-right: 0;
    }
  }

  .header {
    color: var(--off-white);
    padding-bottom: 2.75rem;

    @media screen and (max-width: 767px) {
      font-size: 2.25rem;
      padding-bottom: 1.75rem;
    }
  }

  .buttonAndLink {
    display: flex;
    align-items: center;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .link {
    color: var(--off-white);
    font-size: 0.75rem;
    font-weight: normal;
    margin-left: 3rem;

    @media screen and (max-width: 767px) {
      margin-left: 0;
      padding-bottom: 2.75rem;
    }
  }
}
