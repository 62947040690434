.spinner {
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: spinner 0.6s linear infinite;
}

.smallSpinner {
  composes: spinner;
  height: 20px;
  width: 20px;
}

.mediumSpinner {
  composes: spinner;
  height: 30px;
  width: 30px;
}

.largeSpinner {
  composes: spinner;
  height: 50px;
  width: 50px;
}

.blackSpinner {
  box-sizing: border-box;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  border: 4px solid var(--black);
  border-left-color: transparent;
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
