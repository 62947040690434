.artist {
  display: flex;
  align-items: flex-end;
  position: absolute;
  height: 227px;
  width: 227px;
  z-index: 100;
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 767px) {
    height: 188px;
    width: 188px;
  }
}

.previouslySelected {
  z-index: 500;
}

.selected {
  z-index: 1000;

  .actions {
    display: flex;
    opacity: 1;
  }
}

.artist0 {
  top: 0;
  right: 0;
}

.artist1 {
  top: 100px;
  left: 0;
}

.artist2 {
  bottom: 0;
  right: 70px;

  @media screen and (max-width: 767px) {
    right: 35px;
  }
}

.actions {
  display: none;
  opacity: 0;
  padding: 0 0 1.25rem 1.25rem;
  transition: opacity 0.2s ease-in-out;

  a:hover {
    cursor: pointer;

    :global(.svgIcon) {
      g {
        fill: #999;
      }
    }
  }
}

.more {
  margin-left: 5px;
}
