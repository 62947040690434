.mixtape {
  position: relative;
  height: 263px;
  width: 263px;

  @media screen and (max-width: 767px) {
    height: 154px;
    width: 154px;
  }
}

.overlay {
  display: flex;
  background: rgba(0, 0, 0, 0.7);
  color: var(--off-white);
  flex-direction: column;
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  transition: opacity 0.2s ease-in-out;

  @media (hover: none) and (pointer: coarse) {
    display: none;
  }
}

.show {
  opacity: 1;
}

.nameWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 167px;
  margin: 1.5rem 1rem 0;
}

.actions {
  display: flex;
  bottom: 0;
  margin: 0 0 1rem 1.3rem;
  position: absolute;
  z-index: 10;

  a:hover {
    cursor: pointer;

    :global(.svgIcon) {
      g {
        fill: #999;
      }
    }
  }
}

.more {
  margin-left: 0.5rem;
}
