.contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1024px) {
    margin: 0 2.5rem;
  }
  @media screen and (max-width: 767px) {
    margin: 0 1.5rem;
  }
}

.richText {
  padding-bottom: 4.75rem;

  @media screen and (max-width: 767px) {
    padding-bottom: 2.75rem;
  }

  > *:not(:last-child) {
    padding-bottom: 1.75rem;

    @media screen and (max-width: 767px) {
      padding-bottom: 1rem;
    }
  }

  h3 {
    max-width: 730px;

    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
      line-height: 1.5em;
    }
  }

  p {
    max-width: 565px;
  }
}

.richTextMusicLicensing {
  padding-bottom: 2.75rem;

  > *:not(:last-child) {
    padding-bottom: 1.75rem;
    @media screen and (max-width: 767px) {
      padding-bottom: 1rem;
    }
  }

  p {
    max-width: 730px;
    font-size: 1.5rem;
    line-height: 1.5em;
    font-weight: bold;
    @media screen and (max-width: 767px) {
      font-size: 1rem;
      line-height: 1.5em;
      font-weight: bold;
    }
  }
}

.logoGrid {
  width: 100%;
}

.lastRow {
  width: 100%;
  margin-bottom: 5.75rem;

  @media screen and (max-width: 1024px) {
    margin-bottom: 4.75rem;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 3.75rem;
  }
}

.logos {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 8rem;
  @media screen and (max-width: 1024px) {
    justify-content: space-evenly;
    margin: 0;
    flex-wrap: wrap;
  }
}

.logoContainer {
  display: flex;
  width: 200px;
  height: 200px;
  align-items: center;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 1200px) {
    width: 150px;
    height: 150px;
  }

  @media screen and (max-width: 850px) {
    flex: 50%;
    height: 200px;
    width: 200px;
  }
}

.hoverContent {
  position: absolute;
  width: 120%;
  height: 120%;
  color: white;
  opacity: 0%;
  transition: opacity ease-in-out 0.1s;

  text-align: center;
  display: block;
  align-content: center;

  @media screen and (max-width: 1200px) {
    width: 100%;
    height: 100%;
  }

  &:hover {
    opacity: 100%;
  }

  h3 {
    font-size: 4em;
    font-weight: 300;

    @media screen and (max-width: 1200px) {
      font-size: 3em;
    }
  }

  span {
    font-size: 2.5em;
    @media screen and (max-width: 1200px) {
      font-size: 2em;
    }
  }
}

.more {
  position: absolute;
  bottom: 10px;
  left: 10px;
  svg {
    fill: white;
  }
}

.logo {
  width: 200px;
  height: 200px;
  @media screen and (max-width: 1200px) {
    width: 150px;
    height: 150px;
  }
}

.logosWrapper {
  width: 100%;
}
