.videoGridItem {
  position: relative;
  overflow: hidden;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: auto;
  }

  :global(.PlayIcon) {
    position: absolute;
    top: 20px;
    right: 20px;
    display: unset;
    filter: invert(100%);
  }

  &:hover {
    :global(.PlayIcon) {
      mix-blend-mode: difference;
    }

    :global(.videoDetails) {
      bottom: 0px;
      transition: all 0.2s ease-in-out;
    }
  }
}

.videoDetails {
  width: 100%;
  height: 60%;
  background-color: white;
  color: black;
  position: absolute;
  transition: all 0.2s ease-in-out;
  bottom: -60%;

  :global(.hThree) {
    font-size: 1.5rem;
    margin-top: 2rem;
  }
}

:global(.videoDetails) {
  transition: all 0.2s ease-in-out;
}

.composerAndTags {
  display: block;
  margin: 20px auto;
}

.composer {
  color: var(--gold);
  font-family: Obibok;
  font-size: 0.8rem;
  font-weight: 100;
  letter-spacing: 0.1em;
  display: flex;

  :global(.icon > svg) {
    height: 15px;
    margin-right: 7px;
  }

  @media screen and (max-width: 767px) {
    font-size: 0.65rem;
    font-weight: normal;
    line-height: 1.25rem;
  }
}

.imgWrapper {
  position: relative;
}

.thumb {
  object-fit: cover;
  width: 600px;
  height: 350px;

  @media screen and (max-width: 1410px) {
    width: 513px;
    height: 288px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: auto;
    max-height: 400px;
    min-height: 225px;
  }
}

.tags {
  margin-top: 1rem;
  display: flex;
}

.tag {
  font-family: "Obibok";
  font-size: 0.7rem;
  font-weight: 100;
  background-color: var(--light-grey);
  padding: 6px 15px;
  border-radius: 16px;
  margin: 0px 0.5rem 0px 0px;
  text-transform: uppercase;
  &:first-child {
    margin-left: 0px;
  }
}
