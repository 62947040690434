.draft {
  :global(.prismicMasthead) {
    padding-bottom: 1.75rem;

    :global(.horizontalRule) {
      margin-bottom: 1.75rem;
    }
  }

  :global(.horizontalRule) {
    margin-bottom: 4.75rem;
  }
}
