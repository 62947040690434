.responseMsg {
  background: var(--blue);
  border-radius: 5px;
  color: #fff;
  font-size: 0.9rem;
  font-weight: bold;
  margin: -30px 0 10px 0;
  padding: 8.5px;
  text-align: center;
}

.error {
  background: var(--red);
}

.success {
  background: var(--green);
}
