.wrapper {
  display: flex;
  justify-content: center;
}

.button {
  display: flex;
  background-color: var(--gold);
  border: 0;
  cursor: pointer;
  height: 54px;
  margin-bottom: 4.75rem;
  width: 242px;
  z-index: 10;
  transition: background-color 0.2s ease-in-out;

  @media screen and (max-width: 767px) {
    margin-bottom: 3.75rem;
    width: 205px;
  }

  &:hover {
    background-color: rgba(197, 139, 43, 0.6);

    :global(.svgIcon) {
      g {
        fill: #000;
      }
    }

    .link {
      color: #000;
    }
  }

  &:active {
    background-color: var(--gold);

    :global(.svgIcon) {
      g {
        fill: var(--off-white);
      }
    }

    .link {
      color: var(--off-white);
    }
  }
}

.coral {
  background-color: var(--coral);

  &:hover {
    background-color: rgba(240, 98, 86, 0.6);
  }

  &:active {
    background-color: var(--coral);
  }
}

.green {
  background-color: var(--green);

  &:hover {
    background-color: rgba(33, 146, 142, 0.6);
  }

  &:active {
    background-color: var(--green);
  }
}

.purple {
  background-color: var(--purple);

  &:hover {
    background-color: rgba(116, 91, 180, 0.6);
  }

  &:active {
    background-color: var(--purple);
  }
}

.white {
  background-color: #fff;
  margin-bottom: 0;
  width: 242px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.6);
  }

  &:active {
    background-color: #fff;

    .background {
      opacity: 1;
    }

    :global(.svgIcon) {
      g {
        fill: #000;
      }
    }

    .link {
      color: #000;
    }
  }

  :global(.svgIcon) {
    g {
      fill: #000;
    }
  }

  .link {
    color: #000;
  }
}

.iconAndText {
  display: flex;
  align-items: center;
  margin: 0 1rem;
  width: 100%;

  @media screen and (max-width: 767px) {
    margin: 0 0.5rem;
  }
}

.link {
  color: var(--off-white);
  font-family: Obibok;
  font-size: 0.75rem;
  flex: 1;
  letter-spacing: 0.1em;
  line-height: 54px;
  height: 100%;
  text-align: center;
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  &.noIndent {
    text-indent: 0;
  }
}
