.home {
  padding-top: 0px;

  :global(.prismicMasthead) {
    padding-bottom: 7.5rem;

    div > div:first-of-type {
      padding-top: 4.75rem;

      @media screen and (max-width: 767px) {
        padding: 2.75rem 0 1.75rem;
      }
    }

    @media screen and (max-width: 767px) {
      padding-bottom: 3.75rem;
    }
  }
}
