.videoItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 215px;
  position: relative;
  transition: min-height 0.2s ease-in-out;

  &:last-of-type {
    .bottomBorder {
      border-top: 2px solid #000;
      bottom: 0;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
}

.expanded {
  min-height: 268px;

  @media (hover: none) and (pointer: coarse) {
    min-height: 215px;
  }
}

.topBorder {
  border-top: 2px solid #000;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
}

.imgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  position: absolute;
  overflow: hidden;
  right: 90px;
  top: -30px;
  width: 513px;
}

.thumb {
  opacity: 0;
  width: 100%;
  transition: opacity 0.2s ease-in-out;

  @media (hover: none) and (pointer: coarse) {
    display: none;
  }
}

.showThumb {
  opacity: 1;
}

.videoContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  :global(.PlayIcon) {
    height: 60px;
    width: 60px;

    @media screen and (max-width: 767px) {
      width: 40px;
    }

    g {
      fill: #000;
    }
  }
}

.titleAndComposer {
  max-width: 80%;

  @media screen and (max-width: 767px) {
    max-width: 83%;
  }
}

.title {
  padding-bottom: 0.75rem;

  @media screen and (max-width: 767px) {
    font-size: 1.5rem;
    line-height: 1.5em;
  }
}

.textWrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 45%;
}

.composer {
  color: var(--gold);
  font-family: Obibok;
  font-size: 1rem;
  font-weight: 100;
  letter-spacing: 0.1em;

  @media screen and (max-width: 767px) {
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1.25rem;
  }
}
