.videoSubNav {
  display: flex;
  flex-direction: row-reverse;
  margin: 0 5.5rem;

  @media screen and (max-width: 1024px) {
    margin: 0 2.5rem;
    display: flex;
    flex-flow: row-reverse wrap;
  }

  @media screen and (max-width: 767px) {
    margin: 0 1.5rem;
    display: flex;
    flex-flow: row-reverse wrap;
  }
}

.navItem {
  padding: 10px;
  background: var(--light-grey);
  border-radius: 20px;
  font-family: "Obibok";
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 300;
  margin: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: var(--purple);
    color: white;
  }
}

.active {
  background-color: var(--purple);
  color: white;
}
