:global(.accordionItem) {
  margin: 0 11rem 2rem 11rem;

  @media screen and (max-width: 1024px) {
    margin: 0 5.5rem 2rem 5.5rem;
  }

  @media screen and (max-width: 767px) {
    margin: 0 0 2rem 0;
  }
}

:global(.contentWrapper) {
  display: none;
  text-align: center;
  padding: 2rem;

  @media screen and (max-width: 1024px) {
    display: block;
    padding: 0;
  }
}

:global(.hTwo) {
  display: block;
  border-bottom: solid 0px var(--gold);
  padding-right: 3rem;

  @media screen and (max-width: 1024px) {
    font-size: 2.25rem;
    hyphens: auto;
    overflow-wrap: break-word;
    margin-bottom: 1rem;
    padding-right: 0;
  }
}

:global(.PlayIcon) {
  display: none;

  g {
    fill: #000;
  }
}

.videoWrapper {
  text-align: right;
  padding-right: 1rem;
}

.richText {
  text-align: justify;
  text-align-last: left;
}

.imgWrapper {
  max-width: 100%;

  img {
    max-width: 100%;
  }
}

.headingText {
  background-color: white;
  position: relative;
  bottom: -12px;
  padding-right: 10px;

  @media screen and (max-width: 1024px) {
    background-color: transparent;
    bottom: 0;
    padding: 0;
  }
}

/* Video popup styles */

:global(.accordionItem.VideoPopup) {
  cursor: pointer;

  .popupWrapper {
    @media screen and (max-width: 1024px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
    }
  }

  :global(.hTwo) {
    @media screen and (max-width: 1024px) {
      flex: 1 1 80%;
    }
  }

  :global(.PlayIcon) {
    @media screen and (max-width: 1024px) {
      flex: 0 0 20%;
      height: 45px;
      width: 45px;
      display: initial;
      padding-bottom: 12px;
      text-align: right;
    }
  }
}

:global(.contentWrapper.VideoPopup) {
  padding: 1rem 1rem 2rem;

  @media screen and (max-width: 1024px) {
    display: none;
    padding: 0;
  }
}

/* styles on hover */

.showThumb {
  margin-bottom: 0;

  :global(.contentWrapper) {
    display: block;

    @media screen and (max-width: 1024px) {
      display: block;
    }
  }

  :global(.contentWrapper.VideoPopup) {
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  :global(.hTwo) {
    border-bottom-width: 2px;

    @media screen and (max-width: 1024px) {
      border-bottom-width: 0px;
    }
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: 2rem;
  }
}
