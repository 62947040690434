.imageWrapper {
  padding-bottom: 4.75rem;

  @media screen and (max-width: 1024px) {
    padding-bottom: 3.25rem;
  }
}

.contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5.5rem;

  @media screen and (max-width: 1024px) {
    margin: 0;
  }
}

.image {
  max-width: 920px;
  height: auto;
  margin-bottom: 0.75rem;

  @media screen and (max-width: 1024px) {
    max-width: 100%;
  }
}
