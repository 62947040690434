.browseMixtapes {
  padding-top: 4.5rem;
  padding-bottom: 1rem;

  @media screen and (max-width: 767px) {
    padding-top: 3rem;
    padding-bottom: 0.5rem;
  }

  :global(.textWrapper) {
    margin-right: 0;
    max-width: 975px;

    h3 {
      font-size: 1.5rem;
      font-weight: 500;
      text-align: center;

      @media screen and (max-width: 767px) {
        font-size: 1.3rem;
      }
    }

    p {
      text-align: center;
    }
  }

  :global(.horizontalRule) {
    width: 100%;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}
