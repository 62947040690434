.mixtapes {
  display: grid;
  grid-template-columns: repeat(auto-fill, 263px);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  justify-content: center;
  justify-items: center;
  width: 1105px;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, 154px);
  }
}
