.hero {
  padding-bottom: 4.75rem;

  @media screen and (max-width: 767px) {
    padding-bottom: 3.75rem;
  }

  :global(.hOne) {
    font-family: ObibokInversed;
    font-weight: 400;
    font-size: 3rem;
  }

  :global(.hTwo) {
    font-family: ObibokInversed;
    font-weight: 400;
  }
}

.contain {
  display: flex;
  margin: 0 5rem;

  @media screen and (max-width: 1024px) {
    margin: 0 2.5rem;
  }

  @media screen and (max-width: 767px) {
    margin: 0 1.5rem;
  }
}

.imageCol {
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.descriptionCol {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 3.5rem;
  padding-bottom: 1.75rem;

  @media screen and (max-width: 1024px) {
    margin-left: 0;
  }

  h1,
  h2 {
    padding-bottom: 1.75rem;

    @media screen and (max-width: 767px) {
      font-size: 2.25rem;
    }
  }

  p:last-of-type {
    padding-bottom: 2.75rem;
  }

  :global(.prismicButtonWrapper) {
    justify-content: flex-start;
  }

  :global(.prismicButton) {
    margin-bottom: 0;
  }
}
