.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "proxima-nova", sans-serif;
  font-size: 2rem;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.itemBlur {
  filter: blur(3px);
}
