.videoPlaylist {
  padding-bottom: 4.75rem;

  @media screen and (max-width: 767px) {
    padding-bottom: 2.75rem;
  }
}

.contain {
  margin: 0 5.5rem;

  @media screen and (max-width: 1024px) {
    margin: 0 2.5rem;
  }

  @media screen and (max-width: 767px) {
    margin: 0 1.5rem;
  }
}

.gridPlaylist {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 30px;
}

.nav {
  margin-bottom: 1rem;
}

.paginationContainer {
  margin: 2em auto 0;
  max-width: 1140px;
  position: relative;
  display: flex;
  justify-content: center;
}

.paginationList {
  &ul {
    text-align: center;
  }
}

.paginationListItem {
  display: inline-block;
  margin: 0px 5px 0px;
  cursor: pointer;

  a {
    color: black;
    font-family: NeuzeitGro, georgia;
    font-size: 0.9rem;
    display: inline-block;
    padding: 2px 7px 2px;
    text-decoration: none;
    user-select: none;

    &:hover {
      color: #745bb4;
    }
  }

  :global(.pageNavLink) {
    font-family: NeuzeitGro, georgia;
    font-size: 1.5rem;
    color: var(--grey);
    padding: 0;
    margin: 0 2px;
    display: inline-block;
    width: 20px;
    position: relative;
    top: 1px;
    user-select: none;
  }
}

.next {
  margin-left: 15px;
}

.active {
  color: white !important;
  background-color: #745bb4;
}

.hidden {
  display: none;
}
