.loading,
.error {
  display: flex;
  font-family: NeuzeitGro, georgia;
  font-size: 2rem;
  min-height: 200px;
  padding: 60px;
}

.loading {
  justify-content: center;
}

.error {
  align-items: center;
  flex-direction: column;

  p {
    font-size: 2rem;
  }
}

.footer {
  color: var(--black);
  font-family: Obibok;
  font-size: 0.9rem;
  font-style: italic;
  font-weight: 100;
  line-height: 1.25rem;
  margin: 50px auto 90px;
  text-align: center;

  @media screen and (max-width: 767px) {
    padding: 0 20px;
  }
}

.contain {
  height: 100%;
  margin: 0 auto;
  max-width: 1140px;
  width: 100%;
}

.emailLink {
  color: var(--purple);
  text-decoration: none;
}
