.dropdownMenu {
  display: flex;
  justify-content: flex-end;
  margin: 2em 0;
  font-family: NeuzeitGro, georgia;
  font-size: 14px;

  .rrs:last-child {
    margin-left: 2em;
  }

  @media screen and (max-width: 1200px) {
    margin: 3em 1.5em;
  }

  @media screen and (max-width: 615px) {
    .loader {
      display: none;
    }
  }

  @media screen and (max-width: 580px) {
    margin-bottom: 3em;
    flex-direction: column;

    .rrs {
      &:last-child {
        margin-top: 3em;
        margin-left: 0;
      }
    }
  }
}
