.button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "proxima-nova", sans-serif;
  box-shadow: none;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: 600;
  height: 60px;
  letter-spacing: 1.92px;
  outline: none;
  padding: 0 3em;
  text-decoration: none;
  text-shadow: none;
  text-transform: uppercase;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.black {
  composes: button;
  background: var(--black);
  color: var(--white);

  &:hover,
  &:focus {
    background: #262220;
    transition: background 150ms;
  }
}

.spinner {
  composes: smallSpinner from "../../shared/styles/spinners.module.scss";
  margin-left: 1.5em;
}
