.team {
  padding-bottom: 5rem;

  @media screen and (max-width: 1159px) {
    padding-bottom: 1.5rem;
  }
}

.contain {
  margin: 0;

  @media screen and (max-width: 1159px) {
    margin: 0 5rem;
  }

  @media screen and (max-width: 767px) {
    margin: 0 1.5rem;
  }
}

.header {
  width: 60%;
  margin: 2rem auto 5rem auto;
  display: flex;
  align-items: center;

  h3 {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 1159px) {
    justify-content: flex-start;
    margin-bottom: 2.5rem;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.teamItems {
  width: 1100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;

  @media screen and (max-width: 1159px) {
    justify-content: space-evenly;
    width: auto;
  }
}
