.emailSignUpContainer {
  padding-bottom: 2.75rem;
}

.contain {
  @media screen and (max-width: 1024px) {
    flex-direction: unset;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    height: 100%;
  }
}

.text {
  @media screen and (max-width: 1024px) {
    flex: 2;
    margin: unset;
    padding: 1.75rem;
  }
}
