.masthead {
  padding-bottom: 3.75rem;

  @media screen and (max-width: 767px) {
    padding-bottom: 3.25rem;
  }
}

.contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5.5rem;
  text-align: center;

  @media screen and (max-width: 1024px) {
    margin: 0 2.5rem;
  }

  @media screen and (max-width: 767px) {
    margin: 0 1.5rem;
  }

  h3 {
    font-family: Obibok;
    font-weight: bold;
    margin: 0 5.5rem;
    padding-bottom: 3.25rem;

    @media screen and (max-width: 1024px) {
      margin: 0;
    }

    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
      font-weight: 200;
      line-height: 1.5em;
      padding-bottom: 1.75rem;
    }
  }
}

.kicker {
  font-family: Obibok;
  font-weight: bold;
  font-size: 1rem;
  line-height: normal;
  padding: 0 0 3.25rem;

  @media screen and (max-width: 767px) {
    padding: 0 0 1.75rem;
  }
}
