.selectSongWrapper {
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  min-height: 350px;
  min-width: 600px;
  height: 75%;
  width: 75%;
}

.selectSongFormContainer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.selectSongForm {
  flex: 1;
  margin-bottom: 20px;
  padding: 1rem 2rem 0 2rem;
}

.header {
  margin-bottom: 20px;
  text-align: center;
}

.input {
  margin-bottom: 0;
  margin-right: 1em;

  div {
    position: absolute;
  }
}

.submit {
  margin-top: 0;
  height: 46px;
  line-height: 46px;

  &:active {
    background: #000;
    border-color: #000;
  }
}

.resultsWrapper {
  flex: 6;
  height: 100%;
}

.resultsHeader {
  display: flex;
  align-items: center;
  border-bottom: 2px solid var(--lighter-grey);
  color: #999;
  font-family: "proxima-nova", sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  height: 40px;
}

.resultsScroll {
  height: calc(100% - 42px);
  overflow: auto;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: var(--grey);
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 1em 0;
}

.errorMessage {
  padding: 1rem;
  text-align: center;

  p {
    font-size: 2rem;
  }
}

.noResults {
  font-size: 2rem;
  padding: 1rem;
  text-align: center;
}

.cancelSelectionButton {
  composes: button from "../../../shared/styles/buttons.module.scss";
  border-radius: 10px;
  background: var(--dark-grey);
  border: 0;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
  color: var(--white);
  transition: background 150ms;

  &:hover,
  &:focus {
    background: var(--darker-grey);
  }

  &:active {
    background: var(--darkest-grey);
    box-shadow: 0 1px 5px #000;
  }
}
