.playAll {
  background-color: var(--purple);
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 47px;
  margin-right: 2px;
  outline: none;
  width: 62px;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: var(--black);
  }

  &:active {
    background-color: var(--purple);
  }

  svg {
    height: 45px;
    width: 45px;
    transition: fill 0.1s ease-in-out;

    g {
      fill: #fff;

      :global(.icon-border) {
        display: none;
      }
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
