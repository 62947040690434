.studio {
  :global(.prismicMasthead) {
    padding-bottom: 1.75rem;

    div > div:first-of-type {
      padding-top: 4.75rem;
      @media screen and (max-width: 767px) {
        padding: 2.75rem 0 1.75rem;
      }
    }

    :global(.horizontalRule) {
      margin-bottom: 1.75rem;
    }
  }

  :global(.horizontalRule) {
    margin-bottom: 5.75rem;
  }

  :global(.text2) {
    padding-bottom: 1.75rem;
  }
}
