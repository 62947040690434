.teamItem {
  width: 268px;
  height: 450px;
  flex-grow: 0;
  flex-basis: auto;

  /* the first column of images */
  &[data-position="4"] {
    margin-top: -100px;

    /* switch to uniform spacing in smaller screens */
    @media screen and (max-width: 1159px) {
      margin-top: 0px;
    }
  }

  /* the middle column of images */
  &[data-position="2"],
  &[data-position="5"] {
    margin-top: 0px;
    margin-left: 148px;

    /* switch to uniform spacing in smaller screens */
    @media screen and (max-width: 1159px) {
      margin-top: 0px;
      margin-left: 0px;
    }
  }

  /* the third column of images */
  &[data-position="3"],
  &[data-position="6"] {
    margin-top: 100px;
    margin-left: 148px;

    /* switch to uniform spacing in smaller screens */
    @media screen and (max-width: 1159px) {
      margin-top: 0px;
      margin-left: 0px;
    }
  }

  /* the second image needs special handling */
  &[data-position="2"] {
    margin-top: 100px;

    @media screen and (max-width: 1159px) {
      margin-top: 0px;
    }
  }

  /* the third image needs special handling */
  &[data-position="3"] {
    margin-top: 200px;

    @media screen and (max-width: 1159px) {
      margin-top: 0px;
    }
  }

  @media screen and (max-width: 767px) {
    height: auto;
  }

  @media screen and (max-width: 1159px) {
    margin-top: 0px;
    margin-bottom: 25px;
    margin-right: 1rem;
  }

  :global(.lazyWrapper) {
    height: 268px;
    width: 268px;
  }

  :global(.lazy) {
    height: 100%;
    width: 100%;
  }
}

.imgWrapper {
  width: 100%;
  position: relative;

  img {
    width: 100%;
  }
}

.teamItemText {
  > * {
    margin-top: 1.5rem;
  }
}

.teamItemLinks {
  margin-top: 1.5rem;
  position: absolute;
  z-index: 999;

  @media screen and (max-width: 1159px) {
    margin-bottom: 1rem;
  }

  > a,
  button {
    position: absolute;
    top: 205px;
    left: 16px;
    filter: drop-shadow(-2px 1px 1px rgb(10, 10, 10));
  }

  > button {
    background-color: Transparent;
    outline: none;
    border: none;
  }

  a:nth-of-type(2) {
    position: absolute;
    top: 205px;
    left: 55px;
  }

  > a:hover,
  button:hover {
    filter: drop-shadow(0px 1px 1px rgb(14, 14, 14));
    cursor: pointer;
    :global(.PlayIcon),
    :global(.MoreIcon) {
      display: inline;

      g {
        fill: #999;
      }
    }
  }

  :global(.PlayIcon),
  :global(.MoreIcon) {
    display: inline;

    g {
      fill: white;
    }
  }
}

[data-position="0"] {
  display: none;
}

/* Styling for modal content */

.modalContent {
  background: white;
  max-height: 712px;
  max-width: 420px;
  border: 1px solid black;
  padding: 0 5rem 2rem 5rem;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-y: scroll;
  overflow-x: hidden;

  .teamItemText {
    margin-left: 1rem;
    p {
      font-size: 1.1rem;
    }
  }

  @media screen and (max-width: 767px) {
    max-width: fit-content;
    min-height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
    min-height: calc(
      var(--vh, 1vh) * 100
    ); /* Solution to ensure modal content isn't blocked by browser mobile nav */
    padding: 0 2.5rem 1rem 2.5rem;
    border: none;
  }
}
.modalContent::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.topSection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.exitButton {
  background-color: Transparent;
  outline: none;
  border: none;
  font-size: 20px;
  font-weight: bolder;
  font-family: Obibok;
  cursor: pointer;
  margin-right: -4rem;
  margin-top: 1rem;

  @media screen and (max-width: 767px) {
    margin-right: -2rem;
  }
}

.photoContainer {
  width: 90%;
  margin-left: 1rem;

  img {
    width: 100%;
  }
}

.bioSection {
  margin-top: 1.5rem;
  margin-left: 1rem;
  :global(.paragraph),
  :global(.hOne),
  :global(.hTwo),
  :global(.hThree),
  :global(.hFour),
  :global(.hFive),
  :global(.hSix) {
    font-family: NeuzeitGro;
  }
  :global(.paragraph) {
    line-height: 19px;
    letter-spacing: 0.5px;
  }
}

.outsideLink {
  margin-top: 1.5rem;
  margin-left: 0.5rem;
  img {
    height: 45px;
  }
}
