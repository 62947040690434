.playerWrapper {
  width: 72%;
  padding-bottom: 4.75rem;

  @media screen and (max-width: 767px) {
    padding-bottom: 3.75rem;
    width: 100%;
  }
}

.loading {
  display: flex;
  color: #fff;
  font-family: Obibok;
  font-size: 3rem;
  letter-spacing: 0.1rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.iframeWrapper {
  height: 0;
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  margin-bottom: 0.75rem;
}

.iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
