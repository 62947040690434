.savedCards {
  @media screen and (max-width: 1180px) {
    padding: 0 20px;
  }
}

.contain {
  border-top: 2px solid #e7e7e7;
  height: 100%;
  margin: 0 auto;
  max-width: 1140px;
  padding: 70px 0 100px 0;
  width: 100%;

  @media screen and (max-width: 767px) {
    padding: 50px 0 100px;
  }
}

.header {
  margin-bottom: 1em;
}

.maxCardsMessage {
  background-color: var(--blue);
  border-radius: 10px;
  padding: 10px;

  p {
    color: #fff;
    font-size: 0.9rem;
    letter-spacing: 0.1rem;
    line-height: 1.5em;
  }
}

.responseMessage {
  p {
    color: var(--purple);
    font-family: Obibok;
    font-size: 0.75rem;
    font-weight: normal;
    letter-spacing: 0.1rem;
    line-height: 1.5em;
  }
}

.tableHead {
  border-bottom: 2px solid #e7e7e7;
  color: var(--grey);
  display: flex;
  align-items: center;
  font-family: NeuzeitGro, georgia;
  font-size: 14px;
  height: 54px;
  letter-spacing: 0.1rem;
}

.icon {
  flex: 0.75;

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.info {
  flex: 2.5;
}

.expires {
  flex: 1;

  @media screen and (max-width: 767px) {
    flex: 0.9;
  }
}

.remove {
  display: flex;
  justify-content: center;
  flex: 1;

  @media screen and (max-width: 767px) {
    flex: 0.8;
  }
}
