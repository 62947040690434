.featuredContent {
  padding-bottom: 7.5rem;

  @media screen and (max-width: 767px) {
    padding-bottom: 4.75rem;
  }

  :global(.lazyWrapper) {
    min-width: 550px;
    min-height: 398px;
  }
  :global(.lazy) {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}

.contain {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 5.5rem;

  @media screen and (max-width: 1024px) {
    margin: 0 2.5rem;
  }

  @media screen and (max-width: 767px) {
    margin: 0 1.5rem;
  }
}

.content {
  display: flex;
  align-items: center;
  margin-left: 10%;
  padding-bottom: 4.75rem;

  @media screen and (max-width: 1280px) {
    margin-left: auto;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    margin-left: 0;
  }
}

.richText {
  max-width: 450px;

  @media screen and (max-width: 1024px) {
    max-width: 100%;
  }

  > *:not(:last-child) {
    padding-bottom: 1.75rem;

    @media screen and (max-width: 767px) {
      padding-bottom: 1rem;
    }
  }

  h3 {
    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
      line-height: 1.5em;
    }
  }
}

.img {
  @media screen and (max-width: 767px) {
    padding-bottom: 3.75rem;
    width: 270px;
  }
}
