.banner {
  background: #efefed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 205px;
  padding-bottom: 20px;
}

.icon {
  background: var(--white);
  border-radius: 50%;
  margin-bottom: 10px;
  height: 80px;
  width: 80px;

  svg {
    height: 80px;
    width: 80px;

    g {
      fill: var(--green);
    }
  }
}

.subHeader {
  color: var(--black);
  font-size: 0.9rem;
  font-weight: 100;
  padding-bottom: 10px;
}

.emSubText {
  color: var(--darker-grey);
  font-size: 0.9rem;
  font-weight: 100;
  line-height: 1.25rem;
}
