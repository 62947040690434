.item {
  border-bottom: 1px solid #e7e7e7;
  color: var(--black);
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 100;
  height: 63px;
}

.brandIcon {
  svg {
    height: 42px;
    width: 42px;
  }
}

.removeIcon {
  svg {
    cursor: pointer;
    height: 30px;
    width: 30px;

    g {
      fill: var(--black);

      :global(.icon-border) {
        display: none;
      }
    }

    &:hover {
      g {
        fill: #000;
      }
    }
  }
}
