.studioHero {
  padding-bottom: 4.75rem;

  @media screen and (max-width: 1024px) {
    padding-bottom: 2.75rem;
  }

  :global(.hOne) {
    font-family: ObibokInversed;
    font-weight: 400;
    font-size: 11rem;
    line-height: 1;
  }

  :global(.hTwo) {
    font-family: ObibokInversed;
    font-weight: 400;
  }
}

.contain {
  position: relative;
  display: flex;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    margin: 0 2.5rem;
  }

  @media screen and (max-width: 767px) {
    margin: 0 1.5rem;
  }
}

.title {
  margin-left: 4.75rem;
  position: absolute;
  bottom: -4.5rem;

  @media screen and (max-width: 1024px) {
    bottom: unset;
    margin-left: 0;
    position: unset;

    :global(.hOne) {
      font-size: 3rem;
      padding-bottom: 1.75rem;
    }
  }
}

.imageCol {
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.descriptionCol {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 5.5rem;
  padding-top: 4.75rem;
  margin-right: 5.75rem;

  p:last-of-type {
    padding-bottom: 1.75rem;
  }

  @media screen and (max-width: 1024px) {
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
  }

  :global(.prismicButtonWrapper) {
    justify-content: flex-start;
  }

  :global(.prismicButton) {
    margin-bottom: 0;
  }
}
