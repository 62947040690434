.editProfile {
  @media screen and (max-width: 1180px) {
    padding: 0 20px;
  }
}

.contain {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1140px;
  padding: 70px 0 50px 0;
  width: 100%;

  @media screen and (max-width: 767px) {
    padding: 50px 0;
  }
}

.header {
  margin-bottom: 1em;
}

.jobLabel {
  color: #333;
  font-family: NeuzeitGro, georgia;
  font-size: 14px;
  padding-top: 4px;
}

.jobs {
  justify-content: space-evenly;
  margin-bottom: 31px;
  margin-top: 14px;
  overflow: hidden;

  li {
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    font-family: NeuzeitGro, georgia;
    font-size: 14px;
    height: 95px;
    transition: color 0.2s ease-in-out;

    &.jobSelected {
      color: var(--purple);

      svg g {
        fill: var(--purple);
      }
    }
  }
}

.jobIcon {
  cursor: pointer;

  svg {
    height: 70px;
    width: 70px;

    g {
      fill: var(--black);
    }

    &:hover {
      g {
        fill: var(--purple);
      }
    }
  }
}

.button {
  width: 300px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.hiddenInput {
  display: none;
}
