.imageCollage {
  padding-bottom: 7.5rem;

  @media screen and (max-width: 767px) {
    padding-bottom: 4.75rem;
    width: 100%;
  }
}

.image {
  max-width: 50%;

  @media screen and (max-width: 767px) {
    max-width: 100%;
    height: auto;
  }
}

.contain {
  display: flex;
  flex-direction: column;
}

.topRow {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4.75rem;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding-bottom: 3.25rem;
  }
}

.topLeftImage {
  margin-right: 4.75rem;

  @media screen and (max-width: 767px) {
    padding-bottom: 3.25rem;
    margin: 0;
  }
}

.topRightImage {
  align-self: center;
}

.bottomRow {
  display: flex;
  align-items: center;
  justify-content: center;
}
