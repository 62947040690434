.featuredMixtapes {
  padding-bottom: 4.75rem;

  @media screen and (max-width: 767px) {
    padding-bottom: 4.75rem;
  }
}

.contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5rem;

  @media screen and (max-width: 1024px) {
    margin: 0 2rem;
  }

  @media screen and (max-width: 767px) {
    margin: 0 1rem;
  }
}

.richText {
  margin-right: -90px;
  max-width: 665px;
  padding-bottom: 3.75rem;

  @media screen and (max-width: 1024px) {
    margin-right: 0;
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 1.75rem;
  }

  > *:not(:last-child) {
    padding-bottom: 1.75rem;

    @media screen and (max-width: 767px) {
      padding-bottom: 1rem;
    }
  }

  h3,
  p {
    text-align: right;
  }

  h3 {
    padding-bottom: 2rem;

    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
      line-height: 1.5em;
    }
  }
}
