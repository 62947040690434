.fieldset {
  display: flex;
  flex: 1.7;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 767px) {
    justify-content: unset;
    margin: 0 1.75rem;
    padding-bottom: 1.75rem;
  }
}

.form {
  position: relative;
  width: 242px;

  input {
    height: 54px;
    border: 0;
    background-color: rgba(255, 255, 255, 0.6);
    color: #000;
  }
}

.errorMsg {
  background-color: rgba(201, 40, 40, 0.9);
  color: var(--off-white);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: Obibok;
  font-size: 0.8rem;
  height: 100%;
  line-height: 1.5rem;
  padding: 0.75rem;
  position: absolute;
  word-break: break-word;

  button {
    cursor: pointer;
    border: 0;
    font-family: Obibok;
    letter-spacing: 0.1rem;
    height: 30px;
  }
}

/* Error message styling adjustments */
.inputWrapper div {
  background-color: rgba(255, 255, 255, 0.6);
  margin-top: 0;
  padding-bottom: 3px;
  text-indent: 10px;
}

.submitButton {
  display: flex;
  background-color: #fff;
  border: 0;
  cursor: pointer;
  border: 0;
  width: 242px;
  z-index: 10;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.6);
  }

  &:active {
    background-color: #fff;
  }

  :global(.svgIcon) {
    g {
      fill: #000;
    }
  }

  &:disabled {
    pointer-events: none;
  }
}

.buttonText {
  flex: 1;
  color: #000;
  font-family: Obibok;
  font-size: 0.75rem;
  height: 54px;
  letter-spacing: 0.1em;
  line-height: 54px;
  width: 100%;
}

.spinnerWrapper {
  display: flex;
  align-items: center;
}
