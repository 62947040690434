.orderTable {
  clear: both;
}

.orderTableHead {
  font-family: NeuzeitGro, georgia;
  font-size: 14px;
  border-bottom: 2px solid #e7e7e7;
  color: #999;
  margin-bottom: -1px;

  ol {
    display: flex;
    height: 54px;
    padding: 0;

    @media screen and (max-width: 767px) {
      align-items: center;
      justify-content: space-between;
    }
  }
}

.orderNumber {
  display: flex;
  align-items: center;
  flex: 1.3;
}

.endClient {
  display: flex;
  align-items: center;
  flex: 3;
}

.purchaseDate {
  display: flex;
  align-items: center;
  flex: 1;
}

.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 180px;

  @media screen and (max-width: 767px) {
    margin-right: 0;
  }
}

.orderNumber,
.purchaseDate {
  @media screen and (max-width: 767px) {
    font-size: 1rem;
  }
}

.orderTableList {
  position: relative;
}

.noOrders,
.errorMessage {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  padding-top: 2rem;
}
