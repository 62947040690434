.orderItem {
  color: var(--black);
  height: 100%;

  &:last-child {
    border-color: #efefed;
    border-width: 2px;
  }
}

.orderInfo {
  display: flex;
  border-bottom: 1px solid #e7e7e7;
  cursor: pointer;
  font-family: Obibok;
  font-size: 0.9rem;
  font-weight: 100;
  height: 63px;
  padding: 0;
  width: 100%;

  .total {
    margin-right: 180px;
    text-align: right;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-end;
      margin-right: 0;
    }

    .orderDetail {
      display: none;

      svg g {
        fill: #000;

        :global(.icon-border) {
          display: none;
        }
      }
    }
  }

  &:hover {
    background: #e7e7e7;
    border-color: #e7e7e7;
    margin-left: -20px;
    padding: 0 20px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    align-items: center;
    justify-content: space-between;
  }
}

.expanded {
  background: #e7e7e7;
  border-color: #f7f7f7;
  font-weight: 600;
  margin-left: -20px;
  padding: 0 20px;
  width: 100%;

  .total {
    margin-right: 0;

    .orderDetail {
      font-size: 10px;
      width: 180px;
      display: block;

      a {
        text-decoration: none;
        text-transform: uppercase;
        color: var(--purple);
        font-weight: 400;
        letter-spacing: 0.06rem;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.lineItems {
  display: none;
}

.showLineItems {
  display: block;
}

.lineItemTableHead,
.downloadAll {
  background: #f7f7f7;
  border-color: #f7f7f7;
  display: flex;
  align-items: flex-end;
  font-family: NeuzeitGro, georgia;
  font-size: 14px;
  color: #999;
  height: 32px;
  margin-bottom: -1px;
  margin-left: -20px;
  padding: 5px 20px;
  width: 100%;

  :last-child {
    margin-right: 0;
  }
}

.downloadAll {
  display: flex;
  align-items: center;
  height: auto;

  li {
    display: flex;
    align-items: center;
    margin-left: auto;
    background: #3a3532;
    text-align: center;
    padding: 0;
    margin-bottom: 10px;

    a {
      text-decoration: none;
      color: #fff;
      width: 140px;
      padding: 10px 0;
    }

    &:hover {
      background: #262220;
    }
  }

  svg g {
    fill: #fff;

    :global(.icon-border) {
      display: none;
    }
  }
}

.left {
  display: flex;
  flex: 3.3;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.right {
  display: flex;
  flex: 4;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-end;
  }
}

.orderNumber {
  display: flex;
  align-items: center;
  flex: 1.3;
}

.endClient {
  display: flex;
  align-items: center;
  flex: 3;
}

.purchaseDate {
  display: flex;
  align-items: center;
  flex: 1;
}

.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.endClient,
.total {
  @media screen and (max-width: 767px) {
    font-size: 1rem;
  }
}
