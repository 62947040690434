.wrapper {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 20px;
  min-height: 350px;
  min-width: 600px;
  position: absolute;
  height: 75%;
  width: 75%;
}

.headerContainer {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey);
  color: black;
  padding: 2rem;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  flex: 3;
  justify-content: center;
  font-size: 1.3rem;
  border-bottom: 1px solid var(--grey);
  padding: 2rem;

  .chooseSongPrompt {
    margin-bottom: 2rem;
  }
}

.link {
  color: var(--purple);
  font-weight: bold;
  outline: none;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.checkbox {
  margin-right: 1em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  white-space: normal;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  background: var(--light-grey);
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 1.5rem 2rem;
}

.button {
  composes: button from "../../../shared/styles/buttons.module.scss";
  border-radius: 5px;
  border: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  color: #fff;
  height: 50px;
  width: 150px;
  transition: background 150ms;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.cancelButton {
  composes: button;
  background: var(--error-red);

  &:hover,
  &:focus {
    background: var(--coral);
  }

  &:active {
    background: var(--red);
    box-shadow: 0 1px 3px #000;
  }
}

.selectButton {
  composes: button;
  background: var(--light-green);

  &:hover,
  &:focus {
    background: var(--green);
  }

  &:active {
    background: var(--dark-green);
    box-shadow: 0 1px 3px #000;
  }
}

.blanketDealPrompt {
  margin-bottom: 1em;
  font-size: 1.1rem;

  div {
    margin-bottom: 6px;
  }
}
