.text {
  padding-bottom: 4.75rem;

  @media screen and (max-width: 767px) {
    padding-bottom: 3.25rem;
  }
}

.contain {
  margin: 0 5.5rem;

  @media screen and (max-width: 1024px) {
    margin: 0 2.5rem;
  }

  @media screen and (max-width: 767px) {
    margin: 0 1.5rem;
  }
}

.richText {
  > * {
    padding-bottom: 1.75rem;

    @media screen and (max-width: 767px) {
      padding-bottom: 1rem;
    }
  }

  h2 {
    @media screen and (max-width: 767px) {
      font-size: 2.25rem;
    }
  }

  h3 {
    @media screen and (max-width: 767px) {
      font-size: 1.75rem;
    }
  }

  h4 {
    font-family: ObibokInversed;
    font-weight: 400;

    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
      line-height: 1.5em;
    }
  }

  h5 {
    @media screen and (max-width: 767px) {
      font-size: 1.25rem;
      line-height: 1.5em;
    }
  }

  h6 {
    @media screen and (max-width: 767px) {
      font-size: 1rem;
      line-height: 1.5em;
    }
  }

  &.coral {
    h4,
    li::before {
      color: var(--coral);
    }
  }

  &.gold {
    h4,
    li::before {
      color: var(--gold);
    }
  }

  &.green {
    h4,
    li::before {
      color: var(--green);
    }
  }

  &.purple {
    h4,
    li::before {
      color: var(--purple);
    }
  }

  &:not(.quoteboxText) {
    margin: 0 11rem;

    @media screen and (max-width: 1024px) {
      margin: 0 5.5rem;
    }

    @media screen and (max-width: 767px) {
      margin: 0;
    }

    &.center {
      text-align: center;
    }

    &.right {
      text-align: right;
    }

    &.wide {
      margin: 0 3.75rem;

      @media screen and (max-width: 1024px) {
        margin: 0 1.75rem;
      }

      @media screen and (max-width: 767px) {
        margin: 0 1rem;
      }
    }
  }

  &.quoteboxText {
    h4 {
      margin: 0 5.5rem;
      text-align: center;

      @media screen and (max-width: 1024px) {
        margin: 0;
      }
    }

    > *:not(h4) {
      margin: 0 11rem;

      @media screen and (max-width: 1024px) {
        margin: 0 5.5rem;
      }

      @media screen and (max-width: 767px) {
        margin: 0;
      }
    }
  }
}
