.resetPassword {
  @media screen and (max-width: 1180px) {
    padding: 0 20px;
  }
}

.contain {
  border-top: 2px solid #e7e7e7;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1140px;
  padding: 70px 0 50px 0;
  width: 100%;

  @media screen and (max-width: 767px) {
    padding: 50px 0;
  }
}

.header {
  margin-bottom: 1em;
}

.inputsContainer {
  display: flex;

  @media screen and (max-width: 1180px) {
    flex-direction: column;
  }
}

.rowInput {
  width: 100%;

  &:not(:last-child) {
    margin-right: 1.5em;

    @media screen and (max-width: 1180px) {
      margin-right: 0;
    }
  }
}

.button {
  width: 300px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
