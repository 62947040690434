.accordion {
  padding-bottom: 4.75rem;
}

.contain {
  margin: 0 5.5rem;

  @media screen and (max-width: 1024px) {
    margin: 0 2.5rem;
  }

  @media screen and (max-width: 767px) {
    margin: 0 1.5rem;
  }
}
