.banner {
  padding-bottom: 4.75rem;
}

.contain {
  margin: 0 5.5rem;

  @media screen and (max-width: 1024px) {
    margin: 0 2.5rem;
  }

  @media screen and (max-width: 767px) {
    margin: 0 1.5rem;
  }
}

.bannerBox {
  height: 120px;
  width: 100%;
  align-content: center;
}

.scroller {
  max-width: 1200px;
  margin: auto;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.scroller[data-animated="true"] {
  overflow: hidden;
}

.scroller[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll 20s forwards linear infinite;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

.bannerText {
  font-family: "Obibok";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 85px;
  color: #000000;

  @media screen and (max-width: 767px) {
    font-size: 35px;
  }
}

.bannerIcon {
  svg {
    stroke: black;
    stroke-width: 1.4;
    width: 90px;
    height: 90px;

    @media screen and (max-width: 767px) {
      width: 70px;
    }
  }
}

.mountainWavesIcon {
  width: 110px !important;
}
