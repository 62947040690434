.paymentFields {
  background: var(--lighter-grey);
  padding: 15px;
  margin-top: 18px;
  height: min-content;
}

.cvc {
  flex: 0.5 1 0;
}

.postalCode {
  flex: 0.75 1 0;
}
