.subnavContain {
  :global(.dropdownMenu) {
    display: none;

    @media screen and (max-width: 1024px) {
      display: flex;
    }
  }
}

.subnav {
  min-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;

  &.anchorSubnav {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    margin: 0 auto 5rem auto;
    width: 85%;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.contain {
  padding: 15px 0px 15px;
}

.subnavItems {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.subnavItem {
  list-style-type: none;
  font-family: Obibok;
  font-size: 12px;
  padding-right: 30px;
  text-align: center;

  &:last-child {
    padding-right: 0;
  }

  & > a {
    text-decoration: none;
    color: #fff;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &.subnavItemAnchor {
    font-family: Obibok;
    font-size: 1rem;
    padding-top: 32px;
    padding-bottom: 32px;
    & > a {
      color: black;
    }
  }

  @media screen and (max-width: 767px) {
    box-sizing: border-box;
    text-align: center;
    width: 50%;
    padding: 20px;

    &:last-child {
      padding-right: 20px;
    }
  }
}

.coral {
  background-color: var(--coral);
}

.green {
  background-color: var(--green);
}

.purple {
  background-color: var(--purple);
}
