.nav {
  background: #f8f8f8;
  min-height: 70px;
}

.list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  letter-spacing: 0.128em;
  font-size: 0.75rem;
  text-transform: uppercase;

  li {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 767px) {
      width: 50%;
    }

    a {
      cursor: pointer;
      color: var(--purple);
      margin: 27px 20px;
      text-align: center;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
