.lineItemInfo {
  background: #f7f7f7;
  border-bottom: 1px solid #f7f7f7;
  display: flex;
  font-family: Obibok;
  font-size: 0.9rem;
  font-weight: 100;
  line-height: 1.25rem;
  min-height: 40px;
  margin-left: -20px;
  padding: 4px 20px;
  position: relative;
  width: 100%;

  li:last-child {
    margin-right: 0;
  }

  &:last-of-type {
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 10px;
  }
}

.songOrNoteModalButton {
  background: var(--light-purple);
  border-radius: 25px;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  height: 30px;
  outline: none;
  padding: 0 1em;
  transition: background 150ms;

  &:hover,
  &:focus {
    background: var(--purple);
  }

  &:active {
    background: var(--dark-purple);
  }
}

.editIcon {
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-right: 5px;
}

.song,
.artist,
.item,
.notes,
.noteButtonWrapper,
.noteContent,
.cost,
.downloadSong,
.downloadStems {
  display: flex;
  flex: 1 1;
  align-items: center;
  margin-right: 20px;
}

.notes,
.noteButtonWrapper,
.noteContent {
  flex: 2 1;
}

.cost {
  flex: 0 0 80px;
  flex-direction: row-reverse;
  text-align: right;
  margin-right: 40px;

  @media screen and (max-width: 767px) {
    margin-right: 20px;
  }
}

.downloadSong,
.downloadStems {
  flex: 0 0 60px;
  justify-content: center;
  text-align: center;

  a {
    background: #3a3532;
    width: 100%;
    text-align: center;

    &:hover {
      background: #262220;
    }

    &.stemsRequest {
      background: none;
      display: block;
      font-size: 0.75rem;
      color: var(--purple);
      white-space: normal;
      line-height: normal;
      text-decoration: none;

      &:hover {
        background: none;
        text-decoration: underline;
      }
    }
  }

  svg g {
    fill: #fff;

    :global(.icon-border) {
      display: none;
    }
  }
}

.notApplicable,
.notAvailable {
  display: block;
  font-size: 0.65rem;
  color: var(--grey);
  white-space: normal;
  line-height: normal;
}

.noteContent {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 2;
  margin-top: 11px;
  display: flex;
  align-items: flex-start;
  margin-right: 10px;
}

.noteContent::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.errorMessageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--red);
  color: #fff;
  font-weight: bold;
  height: 100%;
  margin-left: -20px;
  opacity: 0.9;
  position: absolute;
  width: 100%;
}

.errorMessage {
  flex: 10;
  padding-left: 1em;
  text-align: center;

  span:not(:last-of-type):after {
    content: " // ";
  }
}

.closeMessage {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  padding-right: 1em;
}

.closeIcon {
  cursor: pointer;
  height: 22px;
  width: 22px;
}

.tbdUpdated {
  animation: flash 1.5s linear;
}

@keyframes flash {
  0% {
    background: var(--purple);
  }
  100% {
    background: #f7f7f7;
  }
}
