.quoteTestimonial {
  padding-bottom: 4.75rem;
}

.contain {
  margin: 0 5.5rem;

  @media screen and (max-width: 1024px) {
    margin: 0 2.5rem;
    :global(img) {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    margin: 0 1.5rem;
  }
}

.quoteBox {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
  position: relative;
}

.quoteTextArea {
  display: block;
  position: relative;
  align-content: center;
  width: 100%;

  :global(.quoteIconTop) {
    position: absolute;
    top: -30px;
    left: 30px;
    @media screen and (max-width: 1024px) {
      width: 50px;
    }
  }

  :global(.quoteIconBottom) {
    position: absolute;
    bottom: -30px;
    right: 30px;
    transform: rotate(180deg);
    @media screen and (max-width: 1024px) {
      width: 50px;
    }
  }
}

.quoteText {
  padding: 30px;

  :global(.paragraph) {
    font-family: "proxima-nova";
    line-height: 1.5rem;
    font-weight: 500;
  }
}

.byLine {
  padding: 30px;
  padding-top: 0px;
  :global(.paragraph) {
    font-family: "proxima-nova";
    font-weight: 500;
    &::before {
      content: "—";
      margin-right: 5px;
    }
  }
}
