.addNoteWrapper {
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  min-height: 150px;
  min-width: 600px;
  height: 35%;
  width: 75%;
}

.addNoteFormContainer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.addNoteForm {
  flex: 1;
  margin-bottom: 20px;
  padding: 1rem 2rem 0 2rem;
}

.inputWrapper {
  margin-right: 1em;
  flex: 1 1 0;
}

.label {
  color: #333;
  font-family: NeuzeitGro, georgia;
  font-size: 1.1rem;
  line-height: 1.8rem;
}

.required {
  color: #333;
  font-family: NeuzeitGro, georgia;
  font-size: 14px;
  line-height: 1.5rem;
}

.warning {
  color: var(--red);
}

.input {
  margin-bottom: 0;
  font-family: NeuzeitGro, georgia;
  font-weight: 400;
  color: var(--black);
  box-sizing: border-box;
  border: 2px solid var(--grey);
  font-size: 16px;
  height: 46px;
  outline: none;
  padding: 0 10px;
  width: 100%;

  &:focus {
    border-color: var(--purple);
  }
}

.updatingMessage {
  margin-top: 2rem;
}

.submit {
  margin-top: 0;
  height: 46px;
  line-height: 46px;

  &:active {
    background: #000;
    border-color: #000;
  }
}

.submitting {
  display: none;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--grey);
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 1em 0;
}

.cancelButton {
  composes: button from "../../../shared/styles/buttons.module.scss";
  border-radius: 10px;
  background: var(--dark-grey);
  border: 0;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
  color: var(--white);
  transition: background 150ms;

  &:hover,
  &:focus {
    background: var(--darker-grey);
  }

  &:active {
    background: var(--darkest-grey);
    box-shadow: 0 1px 5px #000;
  }
}

.inputAddOn {
  margin: 1vw 1vw 0 0;
}

.btnAddOn {
  height: 50px;
  padding: 0 1.8em;
}
