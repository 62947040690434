.switchboard {
  padding-bottom: 4.75rem;
}

.contain {
  margin: 0 5.5rem;

  @media screen and (max-width: 1024px) {
    margin: 0 2.5rem;
  }

  @media screen and (max-width: 767px) {
    margin: 0 1.5rem;
  }
}

.switchItem {
  display: flex;
  align-items: center;
  margin: 0 5.5rem;
  padding-bottom: 4.75rem;

  @media screen and (max-width: 1024px) {
    margin: 0;
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 3.75rem;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  :global(.prismicButtonWrapper) {
    justify-content: flex-start;
  }

  :global(.prismicButton) {
    margin-bottom: 0;
  }

  :global(.lazyWrapper) {
    height: 357px;
    width: 357px;
  }

  :global(.lazy) {
    height: 100%;
    width: 100%;
  }
}

.info {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.richText {
  padding-bottom: 2.75rem;

  @media screen and (max-width: 767px) {
    padding-bottom: 2rem;
  }

  > *:not(:last-child) {
    padding-bottom: 1.75rem;

    @media screen and (max-width: 767px) {
      padding-bottom: 1rem;
    }
  }

  h3 {
    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }
}

.imgWrapper {
  display: flex;
  flex: 1.1;
  justify-content: flex-end;

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.email {
  color: #000;
  font-size: 0.75rem;
  font-weight: normal;
}
