.contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1024px) {
    margin: 0 2.5rem;
  }
  @media screen and (max-width: 767px) {
    margin: 0 1.5rem;
  }
}

.richText {
  padding-bottom: 4.75rem;

  @media screen and (max-width: 767px) {
    padding-bottom: 2.75rem;
  }

  > *:not(:last-child) {
    padding-bottom: 1.75rem;

    @media screen and (max-width: 767px) {
      padding-bottom: 1rem;
    }
  }

  h3 {
    max-width: 730px;

    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
      line-height: 1.5em;
    }
  }

  p {
    max-width: 565px;
  }
}

.richTextMusicLicensing {
  padding-bottom: 2.75rem;

  > *:not(:last-child) {
    padding-bottom: 1.75rem;
    @media screen and (max-width: 767px) {
      padding-bottom: 1rem;
    }
  }

  p {
    max-width: 730px;
    font-size: 1.5rem;
    line-height: 1.5em;
    font-weight: bold;
    @media screen and (max-width: 767px) {
      font-size: 1rem;
      line-height: 1.5em;
      font-weight: bold;
    }
  }
}

.logoGrid {
  padding-bottom: 5.75rem;
  width: 100%;

  @media screen and (max-width: 1024px) {
    padding-bottom: 4.75rem;
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 3.75rem;
  }
}

.logoMusicLicensing {
  max-height: 60px;
  max-width: 150px;
  @media screen and (max-width: 1024px) {
    &:nth-last-child(-n + 2) {
      display: none;
    }
  }
}

.logos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 8rem;
  @media screen and (max-width: 1024px) {
    justify-content: space-around;
    margin: 0;
  }
}

.logo {
  max-height: 80px;

  @media screen and (max-width: 1024px) {
    &:nth-last-child(-n + 2) {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    &:nth-last-child(-n + 4) {
      display: none;
    }
  }
}

.logosWrapper {
  width: 100%;
}
