.results {
  background: #f7f7f7;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--lighter-grey);
  font-size: 1rem;
  height: 50px;

  &:last-of-type {
    border-bottom: none;
  }
}

.selectButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--light-green);
  border-radius: 15px;
  border: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  outline: none;
  transition: background 150ms;
  height: 30px;
  width: 30px;

  &:hover,
  &:focus {
    background: var(--green);
  }

  &:active {
    background: var(--dark-green);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
  }
}

.link {
  color: var(--black);
  outline: none;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.songArtist {
  flex: 5;
  text-indent: 2rem;
}

.length {
  flex: 1;
}

.select {
  display: flex;
  justify-content: center;
  flex: 1;
}
