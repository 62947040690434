.playerWrapper {
  background: #000;
  border: 4px solid #000;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  width: 72%;
}

.loading {
  display: flex;
  color: #fff;
  font-family: Obibok;
  font-size: 3rem;
  letter-spacing: 0.1rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.iframeWrapper {
  height: 0;
  position: relative;
  padding-top: 56.25%; /* for responsive 16:9 aspect ratio */
  width: 100%;
}

.iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.closeModal {
  background: #000;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 0;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  color: var(--off-white);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Obibok;
  font-size: 1rem;
  font-weight: 100;
  letter-spacing: 0.1rem;
  margin: 2px -4px 0 auto;
  height: 40px;
  width: 100px;

  &:hover {
    color: #fff;
    opacity: 0.6;
  }

  @media screen and (max-width: 767px) {
    font-size: 0.75rem;
    height: 35px;
    width: 80px;
  }
}
