.wrapper {
  display: flex;
  margin-bottom: 2rem;
  width: 100%;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 1.75rem;
  }
}

.artistsCluster {
  display: flex;
  justify-content: center;
  flex: 1;

  @media screen and (max-width: 1024px) {
    margin-bottom: 2.75rem;
    width: 100%;
  }
}

.artists {
  position: relative;
  height: 440px;
  width: 400px;

  @media screen and (max-width: 767px) {
    height: 360px;
    max-width: 360px;
  }
}

.tagsCluster {
  display: flex;
  justify-content: space-between;
  flex: 1;
  font-family: Obibok;
  font-size: 1.125rem;
  line-height: 1.5rem;
  position: relative;

  &::after {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    content: "";
    background: linear-gradient(to top, rgba(255, 255, 255, 1) -10%, rgba(255, 255, 255, 0) 90%);
    pointer-events: none; /* so the text is still selectable */
  }

  @media screen and (max-width: 1100px) {
    flex: 2;
    margin-left: 1rem;
  }

  @media screen and (max-width: 1100px) {
    margin-left: 0;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;
    width: 100%;
  }
}

.highlight0 {
  .highlight {
    color: var(--gold);
  }
}

.highlight1 {
  .highlight {
    color: var(--purple);
  }
}

.highlight2 {
  .highlight {
    color: var(--green);
  }
}

.tagCol {
  &:nth-child(3) {
    margin-top: 2rem;
  }

  &:nth-child(4) {
    margin-top: 4rem;
  }

  @media screen and (max-width: 1100px) {
    .tag,
    .tag:not(:last-of-type) {
      margin-bottom: 2.25rem;
    }
  }
}

.tag {
  transition: color 0.2s ease-in-out;

  &:not(:last-of-type) {
    margin-bottom: 2.5rem;
  }
}

.desktopTags {
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.mobileTags {
  display: none;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    display: flex;

    .tag,
    .tag:not(:last-of-type) {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  }

  @media screen and (max-width: 767px) {
    .tag,
    .tag:not(:last-of-type) {
      margin-bottom: 1.5rem;
    }

    .tag {
      &:nth-of-type(3n) {
        display: none;
      }
    }
  }
}
